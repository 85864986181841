export default {
  welcome: "welcome",
  login: "login",
  logout: "logout",
  home: "home",
  upcoming: "upcoming",
  library: "library",
  comments: "comments",
  comment: "comment",
  enterComment: "comment...",
  watchlist: "watchlist",
  movie: "movie",
  watched: "watched",
  settings: "settings",
  color: "color-scheme",
  language: "language",
  english: "english",
  spanish: "spanish",
  japanese: "japanese",
  search: "search",
  searchMovie: "search movie",
  areYouSure: "Are you sure?",
  eraseMovies: "All movies will be erased from the watched list",
  yes: "yes",
  no: "no",
  name: "name",
  submit: "submit",
  clearAll: "clear all",
  noMoviesinWatchedList: "There are currently no movies in your watched list",
  noMoviesinWatchList: "There are currently no movies in your watchlist",
  addToWatchlist: "add to watchlist",
  removeFromWatchlist: "remove from watchlist",
  rating: "RATING: ",
  commentPosted: "comment posted",
};
