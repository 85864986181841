export default {
  welcome: "bienvenido",
  login: "acceso",
  logout: "cerrar sesión",
  home: "inicio",
  upcoming: "en breve",
  library: "biblioteca",
  comments: "comentarios",
  comment: "comentario",
  enterComment: "comentario...",
  watchlist: "lista de películas",
  movie: "película",
  settings: "ajustes",
  color: "color",
  language: "idioma",
  english: "inglés",
  spanish: "español",
  japanese: "japonés",
  search: "buscar",
  searchMovie: "buscar pelicula",
  areYouSure: "Estas seguro?",
  eraseMovies: "Todas las películas se borrarán de la lista de vistas",
  yes: "sí",
  no: "no",
  name: "nombre",
  submit: "enviar",
  clearAll: "limpiar todo",
  noMoviesinWatchedList: "Actualmente no hay películas en su lista de vistas",
  addToWatchlist: "lista de películas",
  removeFromWatchlist: "eliminar de la lista de películas",
  watched: "vista",
  noMoviesinWatchList: "Actualmente no hay películas en su lista",
  rating: "CLASIFICACIÓN: ",
  commentPosted: "comentario publicado",
};
